<template>
  <div class="ad-protocol">
    <ad-navs :fixed="false"></ad-navs>

    <div class="content-container">
      <h2 class="title">灵鸽隐私政策</h2>
          <section class="main">
                        <p>更新日期：2022年9月16日</p>
                        <p>提示条款</p>
                        <p class="indent-style">
                                <span>深圳市云歌人工智能技术有限公司（以下简称“我们”）深知个人信息对您的重要性，也非常重视用户的隐私和个人信息保护。为便于您更好地使用我们提供的“灵鸽”软件的账号及其各项产品和服务（以下简称“灵鸽”），我们可能会收集和使用与您相关的信息。我们将通过《灵鸽隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品和/或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式，以使您感到安心和放心。</span>
                        </p>

                        <p class="indent-style">
                                <span>本隐私政策适用于灵鸽各项产品或服务。需要特别说明的是，本隐私政策不适用于其他第三方向您提供的服务，也不适用于已另行独立设置隐私权政策的产品或服务。</span>
                        </p>
                        <p class="indent-style">
                                <span>
                                        本隐私政策与您所使用的我们的产品和/或服务息息相关，希望您在使用前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，您可以按照本隐私政策的指引做出您认为适当的选择。我们努力用通俗易懂的文字表述，并对<strong>本隐私政策中与您的权益存在重大关系的条款和个人敏感信息，采用粗体字进行标注以提示您注意。</strong>请您知悉，您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品和/或服务，即意味着您同意我们按照本隐私政策（含更新版本）收集、使用、保存、共享和转让您的相关信息。本隐私政策将作为《灵鸽用户使用协议》（以下简称“《用户协议》”）不可分割的一部分，与《用户协议》具有相同的效力并受限于《用户协议》。除另有约定外，本隐私政策所用术语与《用户协议》中的术语具有相同的涵义。</span>
                        </p>
                        <p class="indent-style">
                                <span>请您注意，我们可能会适时对本协议进行修订。当本协议的条款发生变更时，我们会在您登陆及版本更新时以推送通知、弹窗或其他适当形式向您展示变更后的隐私协议。</span>
                        </p>
                        <p class="indent-style">
                                <span>如对本隐私政策内容有任何疑问、意见或建议，您可通过灵鸽提供的各种联系方式与我们联系。感谢您的理解和支持。</span>
                        </p>
                        <p class="indent-style"><span>本隐私权政策将帮助您了解以下内容：</span></p>
                        <p class="indent-style"><span>1、我们如何收集和使用您的个人信息</span></p>
                        <p class="indent-style">
                                <span>2、我们如何使用 Cookie和同类技术</span>
                        </p>
                        <p class="indent-style">
                                <span>3、我们如何共享、转让、公开披露、委托代理您的个人信息</span>
                        </p>
                        <p class="indent-style">
                                <span>4、我们如何保护您的个人信息</span>
                        </p>
                        <p class="indent-style">
                                <span>5、您管理自己的个人信息的权利</span>
                        </p>
                        <p class="indent-style">
                                <span>6、我们如何处理未成年人的个人信息</span>
                        </p>
                        <p class="indent-style">
                                <span>7、我们如何存储您的个人信息</span>
                        </p>
                        <p class="indent-style">
                                <span>8、本隐私政策的修订和更新</span>
                        </p>
                        <p class="indent-style"><span>9、如何联系我们</span></p>
                        <p class="indent-style">
                                <span>我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。</span>
                        </p>
                        <p class="indent-style">
                                <span>对于您在使用我们的产品和/或服务时，我们需要收集的信息类型与用途，我们将结合具体服务在下文向您逐一说明；如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性，并要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以弹窗提示或信息推送等多种形式征得您的同意或确认第三方已经征得您的同意；</span>
                        </p>
                        <p class="indent-style">
                                <span>如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性；如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将及时征得您的明确同意。</span>
                        </p>
                        <p><span>您可以通过本隐私政策介绍的具体方式访问和管理您的信息、注销账户或进行投诉举报。</span>
                        </p>
                        <p><strong> <span>一、我们如何收集和使用您的个人信息</span></strong></p>
                        <p class="indent-style">
                                <span>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。</span>
                        </p>
                        <p class="indent-style"><span>
                                        <span>个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、</span>14周岁以下（含）儿童的个人信息等（我们将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识）。</span>
                        </p>
                        <p class="indent-style"><span>您理解并同意：</span></p>
                        <p class="indent-style">
                                <span>1、我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准。</span>
                        </p>
                        <p class="indent-style">
                                <span>2、为给您带来更好的产品和服务体验，我们将持续努力改进我们的技术，并不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本隐私政策、弹窗、页面提示等方式另行向您说明收集相应信息的目的、范围及使用方式，并为您提供自主选择同意或拒绝的权利，且在征得您明示同意后收集、使用。在此过程中，如有任何疑问、意见或建议，您可通过我们提供的各种联系方式与我们联系，我们会尽快为您作出解答。</span>
                        </p>
                        <p class="indent-style">
                                <span>我们所提供产品或服务的功能分别收集的个人信息类型，以及收集、使用个人信息的规则（例如收集和使用个人信息的目的、方式）如下：</span>
                        </p>
                        <p class="indent-style"><span>1、求职用户</span></p>
                        <p class="indent-style"><span>1.1、基本业务功能</span></p>
                        <p class="indent-style">
                                <strong>
                                        <span>灵鸽APP的基本业务功能为&lt;求职招聘信息交换&gt;，此功能的必要个人信息为：您的移动电话号码和求职者提供的简历信息。</span>
                                </strong>
                                <span>如您不同意授权平台收集、处理的，平台将无法为您提供包括基本服务在内的所有服务。</span>
                        </p>
                        <p class="ml"><span>1.2、注册用户</span></p>
                        <p class="indent-style"><span>1.2.1、灵鸽用户注册</span></p>
                        <p class="indent-style">
                                <strong><span>当您注册灵鸽服务/登录账号时，您需要提供给我们您的手机号码</span>
                                </strong>
                                <span>，我们会向您的手机号码发送短信验证码，以验证您所注册的手机号码真实有效。如果您不提供手机号码进行验证，将导致注册/登录失败，继而无法使用灵鸽的相关功能。如果您同意灵鸽保存您的密码，则我们也将会收集您的密码。</span>
                        </p>
                        <p class="indent-style">
                                <span>	为方便您与其他用户进行交流，系统会在您登陆后自动为您生成随机昵称和默认个人介绍，您可以自行修改，也可以之后在“我的”-“个人信息”-“编辑按钮”中进行修改。</span>
                        </p>
                        <p class="indent-style"><span>1.2.2、第三方用户注册</span></p>
                        <p class="indent-style">
                                <span>我们可能会根据您的授权从第三方处获取您的第三方账户信息，并与您的灵鸽账户进行绑定。我们将在您授权同意的范围内使用您的相关信息。您提供的上述信息将在您使用本服务期间持续授权我们使用。在您主动注销账号时，我们将根据适用法律法规的要求尽快使匿名化或删除您的个人信息。</span>
                        </p>
                        <p class="indent-style">
                                <span>微信用户注册：当您通过微信注册时，可选择授权我们读取并获得您在微信上公开的信息（包括昵称、openid、头像），获取上述信息是为了创建您的灵鸽账户信息，您也可以之后在“我的”-“个人信息”-“编辑按钮”中进行修改。</span>
                        </p>
                        <p class="indent-style"><span>1.3、在线简历</span></p>
                        <p class="indent-style"><span>当您作为求职用户，使用灵鸽的求职功能时，需要创建自己的简历，您可以根据个人意愿，填写下列信息之一或全部：</span>
                        </p>
                        <p class="indent-style">
                                <span>(1)基本信息：头像（可选择使用真人头像）、姓名、性别、出生年月、首次参加工作时间（选填）、现居住地（选填）、手机号码（默认为登录手机号码）、联系邮箱（选填）、微信（选填）、当前状态（在职/离职/在校生）。</span>
                        </p>
                        <p class="indent-style"><span>(2)求职意向：期望职位、期望行业、期望薪资、工作性质（全职，自由/远程，实习）、期望工作地点。</span>
                        </p>
                        <p class="indent-style"><span>(3)自我评价（选填）。</span>
                        </p>
                        <p class="indent-style"><span>(4)工作/实习经历：公司名称、职位名称、税前月薪（选填）、入职离职时间、工作内容、技能标签。</span>
                        </p>
                        <p class="indent-style"><span>(5)项目经历：项目名称、担任角色、项目起始结束时间、项目描述。</span></p>
                        <p class="indent-style"><span>(6)教育经历：学历、学校名称、入学时间、毕业时间。</span></p>
                        <p class="indent-style"><span>(7)附件简历：（选填，包括附件名称和上传的文件）。</span></p>
                        <p class="indent-style">
                                <span>您还可以补充或修改您的求职意向，求职意向中包括期望职位、期望薪资、期望行业、期望地点、核心优势。您完善简历信息有助于企业更快了解您的信息，也有助于灵鸽为您匹配合适的职位信息，提供更好的服务体验，但如果您不填写这些补充信息，不会影响您使用灵鸽的基本功能。</span>
                        </p>
                        <p class="indent-style"><span>1.4、展示简历</span></p>
                        <p class="indent-style">
                                <span>当您创建完毕简历后，可以选择是否开启找工作状态。您可以在机会界面通过点击开启，您可以在机会界面右上角的设置中开启和关闭找工作状态。</span>
                        </p>
                        <p class="indent-style">
                                <span>(1)&nbsp;</span><strong><span>当您开启“找工作”状态时，系统将根据你的求职意向进行匹配，并将您的简历推送至相应的企业和猎头。</span></strong>
                        </p>
                        <p class="indent-style">
                                <span>(2)&nbsp;</span><strong><span>招聘者和猎头在收到系统推送的简历后，可以对您的简历信息进行查询、浏览，但将受到相应服务协议的约束，同时为避免您的个人信息被泄露、滥用，在您同意投递简历前，您的简历及其他个人信息在显示给招聘者和猎头用户时不会展示您的全名和联系方式。</span></strong>
                        </p>

                        <p class="indent-style"><span>(3)&nbsp;</span>
                                <strong>
                                        <span>对您感兴趣的招聘者和猎头用户可以向您发送职位邀请或投递邀请，除非您同意进行投递，否则招聘者和猎头用户无法获悉您的全名和联系方式；如您同意投递简历，则招聘者和猎头方可查看您的全名和联系方式。</span></strong>
                        </p>
                        <p class="indent-style"><span>(4)&nbsp;</span><strong><span>
                        如果您同意，您的简历信息还会展示在发现页面，您和与您有相同求职意向的用户可以查看彼此的职位邀请，其中会展示您的简历（全名和联系方式除外）、口碑评价和求职问答，您可以在“我的”界面-设置-机会设置-内容限制进行开启和关闭。</span></strong>
        </p>
                        <p class="indent-style"><span>(5)&nbsp;</span><strong><span>
                                                除上述情形外，我们不会在未经您允许的情况下向任何第三方共享您的简历信息，我们会通过灵鸽APP内的功能或短信通知的方式向您告知哪些招聘者和猎头查阅了您的简历。</span></strong>
                        </p>
                        <p class="ml"><span>1.5、投递简历</span></p>
                        <p class="indent-style">
                                <span>当招聘者或猎头查看您的简历后，可向您发起邀约，您可以在“我的投递”界面进行查看并操作，该界面可查看您的邀约记录和后续面试进展。接受邀约后，发出邀约的招聘者和猎头方可查看到您的全名和联系方式，用于进一步沟通。</span>
                        </p>
                        <p class="indent-style"><span>1.6、发现功能</span></p>
                        <p class="indent-style">
                                <span>我们为您提供了发现功能，您可以在这里查看与您有相同求职意向的其他求职者，您可以看到他们收到的职位邀请，进而了解他们与该职位的匹配程度，<strong>同时您们还可以查看彼此的简历（全名和联系方式除外），您可以在“我的”界面-设置-机会设置-内容限制进行开启和关闭。</strong></span>
                        </p>
                        <p class="indent-style"><span>1.7、口碑评价</span></p>
                        <p class="indent-style">
                                <span>为了帮助您提升求职认可度，您还可以邀请微信好友进行评价，被邀请人收到邀请后，需要先输入手机号码和验证码完成注册，您可以隐藏或展示好友的评价，评价会展示在您的简历中。<strong>同时其他用户在发现页面查看您的职位邀请信息时还可以看到您的口碑评价。您可以在“我的”界面-设置-机会设置-内容限制进行开启和关闭。</strong></span>
                        </p>
                        <p class="indent-style"><span>1.8、求职问答</span></p>
                        <p class="indent-style">
                                <span>为帮助您更深入了解企业/岗位，您在求职问答中可添加关注问题，系统将在企业端提醒企业方回答您所关注的问题，同时也会有企业向您发起问题，在您回答后，企业将可以在您的简历页中浏览到您的回答。<strong>同时其他用户在发现页面查看您的职位邀请信息时还可以看到您的求职问答，您可以在“我的”界面-设置-机会设置-内容限制进行开启和关闭。</strong></span>
                        </p>
                        <p class="indent-style"><span>1.9、我的钱包</span></p>
                        <p class="indent-style">
                                <span>灵鸽的钱包功能由银联商务股份有限公司及其分公司（以下简称“银联”）向你提供服务。当您首次开通钱包功能时，银联将会收集您的姓名、身份证号、银行卡类型及卡号、有效期及手机号码。<strong>身份证号和银行卡相关信息是个人敏感信息，</strong>这些信息是支付服务所必需的信息，拒绝提供将导致你无法使用该功能，但不影响其他功能的正常使用。</span>
                        </p>
                        <p class="indent-style"><span>1.10、客户服务</span></p>
                        <p class="indent-style">
                                <span>当您与我们联系进行申诉、注销账户、服务咨询，或您的账号可能存在违反灵鸽规则的行为时，为了方便与您联系、帮助您解决问题、违规行为核实及处理，灵鸽可能需要您提供姓名、手机号码、电子邮件、简历、证件号码等一项或多项信息以核验您的用户身份。同时，灵鸽可能会利用您认证后的联系信息与您直接沟通或互动，联系信息包括您的手机号码、电子邮件。为保证您的账号安全，客服在沟通过程中时，会使用您的账号信息核验您的身份。如您拒绝提供上述信息，可能部分功能无法使用，同时无法向您及时反馈申诉、注销或咨询结果。</span>
                        </p>
                        <p class="ml"><span>2、招聘者用户</span></p>
                        <p class="ml"><span>2.1、注册用户</span></p>
                        <p class="indent-style">
                                <strong>
                                        <span>当您注册灵鸽服务/登录账号时，您需要提供给我们您的手机号码，</span>
                                </strong>
                                <span>我们会向您的手机号码发送短信验证码，以验证您所注册的手机号码真实有效。如果您不提供手机号码进行验证，将导致注册/登录失败，继而无法使用灵鸽的相关功能。如果您同意灵鸽保存您的密码，则我们也将会收集您的密码。</span>
                        </p>
                        <p class="indent-style"><span>2.2、招聘用户认证</span></p>
                        <p class="indent-style">
                                <span>当您作为企业招聘者进行企业入驻时，您需要提供公司全称、公司简称、公司地址、所属行业、企业规模，上传公司LOGO、营业执照，进行企业认证。同时</span><strong><span>为确保职位发布者身份的真实性，您需要进行实名认证，填写真实姓名和身份证号码并完成人脸识别。</span></strong><span>您还需要阅读并同意
                                        
                                        <a style="color: blue;text-decoration:underline;"
                                        href="https://appinter.ringleai.com/#/company/agreement"
                                        target="_blank">
                                        《灵鸽企业入驻协议》
                                </a>
                                        。如您不能或拒绝提供上述信息，或不同意《灵鸽企业入驻协议》，将无法使用企业相关功能，但不影响您以个人用户的身份使用灵鸽的其他功能。此外，灵鸽会与您签订《灵鸽服务协议》（需加盖企业公章或合同章）。</span>
                        </p>
                        <p class="indent-style"><span>2.3、发布职位</span></p>
                        <p class="indent-style">
                                <span>当您完成招聘用户认证后，才可以在灵鸽发布职位信息。您发布的职位信息可在灵鸽APP和灵鸽网站（https://resume.ringle.com）展示。</span>
                        </p>
                        <p class="indent-style"><span>2.4、简历匹配</span></p>
                        <p class="indent-style">
                                <span><span>为了提高求职或招聘效率及成功率，系统会根据您发布的职位信息为您匹配合适的求职者，您可以查询、浏览简历信息，为避免个人信息被泄露、滥用，我们将不会展示求职者的全名和联系方式。</span></span>
                        </p>
                        <p class="indent-style"><span>您可以向感兴趣的求职者发送职位投递邀请，求职者同意后，您方可查看其全名和联系方式，用于进一步沟通。</span></p>
                        <p class="indent-style"><span>2.5、求职问答</span></p>
                        <p class="indent-style"><span>
                                为帮助求职者更深入了解您的企业/岗位，您可以在企业端回答求职者向您提出的问题，同时您也可以向求职者发起问题，在回答后，双方可以在求职者的简历页中查看。
                                <strong>同时其他用户在发现页面查看您的职位信息时也可以看到您的求职问答。</strong>
                        </span>
                        </p>
                        <p class="indent-style"><span>2.6、企业钱包</span></p>
                        <p class="indent-style"><span>
                                灵鸽的企业钱包功能由银联商务股份有限公司及其分公司（以下简称“银联”）向你提供服务。您需要先开通好易联企业电子钱包，并与您的灵鸽账号进行绑定，企业钱包是使用支付服务所必需的信息，拒绝提供将导致你无法使用与企业钱包相关的支付功能，但不影响其他功能的正常使用。</span>
                        </p>
                        <p class="indent-style"><span>2.7、客户服务</span></p>
                        <p class="indent-style">
                                <span>当您与我们联系进行申诉、注销账户、服务咨询，或您的账号可能存在违反灵鸽规则的行为时，为了方便与您联系、帮助您解决问题、违规行为核实及处理，灵鸽可能需要您提供姓名、手机号码、电子邮件、简历、证件号码等一项或多项信息以核验您的用户身份。同时，灵鸽可能会利用您认证后的联系信息与您直接沟通或互动，联系信息包括您的手机号码、电子邮件。为保证您的账号安全，客服在沟通过程中时，会使用您的账号信息核验您的身份。如您拒绝提供上述信息，可能部分功能无法使用，同时无法向您及时反馈申诉、注销或咨询结果。</span>
                        </p>
                        <p class="indent-style"><span>3、猎头用户</span></p>
                        <p class="ml"><span>3.1、注册用户</span></p>
                        <p class="indent-style">
                                <strong><span>当您注册灵鸽服务/登录账号时，您需要提供给我们您的手机号码</span></strong><span>，我们会向您的手机号码发送短信验证码，以验证您所注册的手机号码真实有效。如果您不提供手机号码进行验证，将导致注册/登录失败，继而无法使用灵鸽的相关功能。如果您同意灵鸽保存您的密码，则我们也将会收集您的密码。</span>
                        </p>
                        <p tyle="margin-left:28px;"><span>3.2、猎头认证</span></p>
                        <p class="indent-style">
                                <span>当您作为专业猎头想要使用灵鸽的猎头相关功能时，为便于您与企业HR和候选人沟通，您需要向我们提供从业时长、擅长做单标签、做单城市、猎头经历介绍；
                                        如果您是非猎头的自由职业者，您需要提供您的工作时长、职位类型、所在行业、城市、个人简介。在您使用猎头官功能上传、投递或分享简历，
                                        <strong>
                                                <span>为确保招聘安全，您需要向我们提供您的身份信息（包括真实姓名、身份证号）、人脸识别信息以完成实名认证。如果人脸识别失败您可以通过人工审核的方式提供您的姓名、身份证号、身份证正反面照片并上传您手持身份证照片，来进行实名认证。
                                                       </span> </strong>您还需要阅读并同意
                                                       
                                                       <a style="color: blue;text-decoration:underline;"
                                                       href="https://cdn2.ringle.com/web/tos/headhunting.html?display=none"
                                                       target="_blank">
                                                       《灵鸽猎头协议》
                                               </a>
                                                       。如您不能或拒绝提供上述信息，或不同意《灵鸽猎头协议》，将无法使用猎头相关功能，但不影响您以个人用户的身份使用灵鸽的其他功能。</span>
                        </p>
                        <p class="indent-style"><span>3.3、简历匹配</span></p>
                        <p class="indent-style">
                                <span>为向您匹配更精准的求职招聘信息，我们会要求您设置职位偏好，包括招聘对象、行业和职位，您确认后还可在“我的”-“职位偏好”中进行修改。</span>
                        </p>
                        <p class="indent-style">
                                <span>为了提高人才求职效率及成功率，系统会根据您填写的职位偏好为您匹配合适的求职者，您可以查询、浏览简历信息，为避免个人信息被泄露、滥用，我们将不会展示求职者的全名和联系方式。</span>
                        </p>
                        <p class="indent-style"><span>您可以向感兴趣的求职者发送职位投递邀请，求职者同意后，您方可查看其全名和联系方式，用于进一步沟通。</span></p>
                        <p class="indent-style"><span>3.4、职位收藏</span></p>
                        <p class="indent-style"><span>您可以在机会页，浏览企业发布的职位信息，同时您可以收藏感兴趣的职位，方便您可以更快的找到对应职位。</span>
                        </p>
                        <p class="indent-style"><span>3.5、HunterDAO功能</span></p>
                        <p class="indent-style">
                                <span>HunterDAO是一种全新的猎头团队协作方式，您可以创建属于自己的DAO团队并邀请成员，当您或您的DAO成员在灵鸽完成交付后可以获得额外收益。</span>
                        </p>
                         <p class="indent-style"><span>3.5.1、</span><strong><span>创建DAO：当您获得创建DAO的资格后，您需要填写相关团队资料进行创建，创建后您的DAO将展示在DAO广场，其它猎头用户可在广场查看和申请加入，（在创建时您可以选择是否开启定位权限，开启后您的DAO信息将优先展示给附近的猎头用户，不开启不会影响您使用DAO的其他功能），同时您也可以邀请成员；</span></strong></p>
                        <p class="indent-style"><span>3.5.2、</span><strong><span>申请加入：没有创建资格的猎头用户可以广场查看其他人已创建的DAO,(您可以选择是否开启定位权限，开启后系统会根据地理位置为您展示附近的DAO，不开启不会影响您使用DAO的其他功能），您可以申请加入其中的一个DAO，通过后即可加入；</span></strong></p>
                        
                        <p class="indent-style"><span>3.6、客户服务</span></p>
                        <p class="indent-style">
                                <span>当您与我们联系进行申诉、注销账户、服务咨询，或您的账号可能存在违反灵鸽规则的行为时，为了方便与您联系、帮助您解决问题、违规行为核实及处理，灵鸽可能需要您提供姓名、手机号码、电子邮件、简历、证件号码等一项或多项信息以核验您的用户身份。同时，灵鸽可能会利用您认证后的联系信息与您直接沟通或互动，联系信息包括您的手机号码、电子邮件。为保证您的账号安全，客服在沟通过程中时，会使用您的账号信息核验您的身份。如您拒绝提供上述信息，可能部分功能无法使用，同时无法向您及时反馈申诉、注销或咨询结果。</span>
                        </p>
                        <p class="indent-style"><span>4、</span><strong><span>APP收集个人信息清单</span></strong></p>
                        <p class="indent-style">
                                <span>当您注册并开始使用灵鸽各项服务时，为保障您正常使用我们的服务，维持我们服务的正常运行，改进及优化我们的服务体验以及保障您的帐号安全，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</span>
                        </p>
                        <p class="indent-style"><span>4.1、</span><strong><span>个人常用设备信息</span></strong></p>
                        <p class="indent-style">
                                <span>我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息，包括（设备型号、操作系统版本、设置参数、设备配置、设备标识、设备网络信息、设备环境在内的描述个人常用设备基本情况的软硬件特征信息）。</span>
                        </p>
                        <p class="indent-style"><span>4.2、</span><strong><span>服务日志信息</span></strong></p>
                        <p class="indent-style">
                                <span>当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关服务日志保存。包括您的浏览、点击查看和搜索查询内容、关注分享信息、发布信息，以及IP地址、浏览器的类型、运营商信息、使用的语言、访问日期和时间。</span>
                        </p>
                        <p class="indent-style"><span>4.3、</span>
                                <strong>
                                        <a style="color: blue;text-decoration:underline;"
                                                href="https://cdn2.ringle.com/web/tos/thirdPartyInformation.html"
                                                target="_blank">
                                                关于第三方SDK获取您个人信息的情况，您可以通过点击查看，《灵鸽第三方收集使用个人信息列表》。
                                        </a>
                                </strong>
                        </p>
                        <p class="indent-style">
                                <span>请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span>
                        </p>
                        <p class="ml"><strong><span>5、APP权限获取说明</span></strong></p>
                        <p class="indent-style">
                                <span>您理解并同意，我们提供的服务可能需要您在您的设备中开启您的摄像头（相机）、相册（图片库）、系统存储的访问权限，以实现这些权限所涉及信息的收集和使用。我们会在您使用相关服务时弹窗提示您是否要开启相应权限。</span>
                        </p>
                        <p class="indent-style">
                                <span>您可在您的设备设置“我-设置-隐私-系统权限”中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span>
                        </p>
                        <p class="indent-style">
                                <span>5.1、</span><strong><span>使用相机</span></strong><span>：开启相机权限，为向您提供人脸识别、拍照上传图像（如头像、企业LOGO、猎头认证、意见反馈等）、扫一扫登录PC端相关功能。</span>
                        </p>
                        <p class="indent-style">
                                <span>5.2、</span><strong><span>读写存储（读取/写入/删除/存储空间/照片权限）</span></strong><span>：开启读写存储权限，方便存储图片、上传简历、视频、下载文件、更新应用。</span>
                        </p>
                        <p class="indent-style">
                                <span>5.3、</span><strong><span>定位权限：开启定位权限，在您使用DAO功能时，可根据定位优先为您匹配您附近的DAO</span></strong>
                        </p>
                        <p class="indent-style"><strong><span>6、实名认证</span></strong></p>
                        <p class="indent-style">
                                <strong><span>当您上传简历、发布职位、涉及支付或提现时，您还需要根据法律要求向我们提供相应的身份信息（真实姓名和身份证号）、生物识别特征（静态或动态的面部特征）以完成实名认证。</span></strong>
                        </p>
                        <p class="indent-style">
                                <strong><span>灵鸽采用阿里云的人脸识别技术进行实名认证，您在使用我们的人脸识别服务前应明确同意我们及阿里云使用并传送相关数据用于实人认证审核，我们仅会将您提供的信息用于实人认证。如您拒绝进行实名认证，将无法使用灵鸽的猎头、企业相关功能，支付或提现服务，但不影响您使用其他功能或服务。</span></strong>
                        </p>
                        <p class="indent-style"><span>7、</span><strong><span>支付提现</span></strong></p>
                        <p class="indent-style">
                                <strong><span>当您进行充值、收入提现时，我们会收集您提供的银行卡信息、微信账户信息或支付宝账户信息用于转账付款，以及身份证信息用于确认身份。</span></strong>
                        </p>
                        <p class="indent-style"><span>8、一键登录</span></p>
                        <p class="indent-style">
                                <span>您还可以使用“一键登录”的方式登录灵鸽，当您使用一键登录功能时，我们会从移动、联通或电信运营商（合称“运营商”）处获取您的手机号码，在
                                        <strong><span>您仔细阅读各运营商的相关服务协议并同意后</span></strong>&nbsp;，我们才会从运营商处获取您的手机号为您提供便捷登录，并通过该手机号码为您创建灵鸽账号及为您提供后续服务。</span>
                        </p>
                        <p class="indent-style">
                                <span>9、您上传的照片、个人资料、简历等信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。除非经您自主选择或遵从相关法律法规要求，我们不会对外提供上述动态信息，或者将其用于该功能以外的其他用途。</span>
                        </p>
                        <p class="indent-style">
                                <strong><span>请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发布内容时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</span></strong>
                        </p>
                        <p class="indent-style"><span>若您提供的信息中含有其他用户的个人信息，在向灵鸽提供这些个人信息之前，您需确保您已经取得合法的授权。</span>
                        </p>
                        <p class="indent-style">
                                <span>若我们将信息用于本隐私政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先征求您的同意。</span>
                        </p>
                        <p class="indent-style">
                                <span>10、若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方已依法取得您同意后收集个人信息，并向您告知共享的信息内容，要求第三方将个人敏感信息提供给我们使用前经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团队会对个人信息会进行安全防护（包括敏感信息分级分类、敏感信息使用的访问控制、敏感信息加密存储等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。</span>
                        </p>
                        <p class="indent-style"><span>11、征得授权同意的例外</span>
                        </p>
                        <p class="indent-style">
                                <strong><span>您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需征得您的授权同意，且我们可能会因此无法满足您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：</span></strong>
                        </p>
                        <p class="indent-style"><strong><span>11.1、与个人信息控制者履行法律法规规定的义务相关的；</span></strong>
                        </p>
                        <p class="indent-style"><strong><span>11.2、与国家安全、国防安全有关的；</span></strong></p>
                        <p class="indent-style"><strong><span>11.3、与公共安全、公共卫生、重大公共利益有关的；</span></strong>
                        </p>
                        <p class="indent-style"><strong><span>11.4、与犯罪侦查、起诉、审判和判决执行等有关的；</span></strong>
                        </p>
                        <p class="indent-style">
                                <strong><span>11.5、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span></strong>
                        </p>
                        <p class="indent-style"><strong><span>11.6、所收集的个人信息是您自行向社会公众公开的；</span></strong>
                        </p>
                        <p class="indent-style">
                                <strong><span>11.7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span></strong>
                        </p>
                        <p class="indent-style"><strong><span>11.8、根据您的要求签订和履行合同所必需的；</span></strong></p>
                        <p class="indent-style">
                                <strong><span>11.9、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span></strong>
                        </p>
                        <p class="indent-style"><strong><span>11.10、法律法规规定的其他情形。</span></strong></p>
                        <p class="indent-style">
                                <span>请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善灵鸽提供的服务，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。</span>
                        </p>
                        <p class="indent-style">
                                <span>如我们停止运营灵鸽产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</span>
                        </p>
                        <p><strong><span>二、我们如何使用Cookie 和同类技术</span></strong></p>
                        <p class="indent-style"><span>1、Cookie的使用</span></p>
                        <p class="indent-style"><span>为确保网站正常运转、使您获得更轻松的访问体验，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。
                                Cookie通常包含标识符、站点名称以及一些号码和字符。我们向您发送Cookie是为了简化您重复登录的步骤、存储您的产品及服务搜索等信息进而为您提供偏好设置。
                                我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除软件内保存的所有Cookie，
                                但如果您这样做，那么您可能需要在每一次访问时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</span>
                        </p>
                        <p><strong><span>三、我们如何共享、委托处理、转让、公开披露您的个人信息</span></strong>
                        </p>
                        <p class="indent-style"><span>我们仅会在本隐私政策约定的范围内共享、委托处理、转让、公开披露您的个人信息：</span>
                        </p>
                        <p><strong><span>（一）共享</span></strong></p>
                        <p class="indent-style">
                                <span><span>我们将遵照法律法规的规定，对信息的分享进行严格的限制。我们不会主动与我们以外的公司、组织和个人（</span><span>“第三方”）共享您的个人信息，如您发现我们违反法律法规规定或违反与您的约定向第三方共享您的个人信息，且您要求删除的，我们会立即停止共享的行为，并通知第三方及时删除；但以下情况除外：</span></span>
                        </p>
                        <p class="indent-style">
                                <span>1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您同意共享的个人信息；同时，在共享您的敏感信息前，我们会向您告知所涉及的个人敏感信息类型、数据接收方的身份和数据安全能力，并事先征得您的明示同意。</span>
                        </p>
                        <p class="indent-style">
                                <span>2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要、法律程序的要求，或强制性的行政、司法机关要求所必需的情况下，对外共享您的个人信息；</span>
                        </p>
                        <p class="indent-style">
                                <span>3、在法律法规允许的范围内，为维护我们、我们的关联方或合作伙伴、您或其他用户、社会公众的利益、财产或安全免遭损害而确有必要提供；</span>
                        </p>
                        <p class="indent-style"><span>4、只有共享您的信息，才能实现我们的产品和/或服务的核心功能或提供您需要的服务；</span>
                        </p>
                        <p class="indent-style"><span>5、应您需求为您处理您与他人的纠纷或争议；</span></p>
                        <p class="indent-style"><span>6、符合与您签署的相关协议（包括在线签署的电子协议以及相应的用户协议）或其他的法律文件约定所提供；</span>
                        </p>
                        <p class="indent-style"><span>7、基于学术研究而使用；</span>
                        </p>
                        <p class="indent-style"><span>8、基于符合法律法规的社会公共利益而使用。</span></p>
                        <p class="indent-style">
                                <span>我们会将您的个人信息在我们内部共享，以便于我们向您提供更优质的服务，识别账号异常或保护我们、其他用户或公众的人身财产安全免遭侵害。我们只会共享必要的个人信息（如为便于您使用灵鸽账号或使用我们的产品或服务，我们需要在内部共享您必要的账户信息）且会受本隐私政策中所声明目的的约束，如果我们共享您的个人信息或改变您个人信息的使用及处理目的，我们将再次征求您的授权同意。</span>
                        </p>
                        <p class="indent-style">
                                <span>您同意我们可能会为实现外部处理等目的与第三方合作伙伴（包括但不限于供应商、服务提供商和其他合作伙伴）分享您的个人信息，委托授权他们按照本隐私政策以及其他相关的保密和安全措施为您提供某些服务或代表我们履行职能，如支持我们的业务，包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。我们仅会出于的合法、正当、必要、特定、明确的目的共享您的信息，并且只会共享提供服务所必要的个人信息。授权合作伙伴只能接触到其履行职责所需个人信息，且无权将该等个人信息用于其他任何目的。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，确保其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。</span>
                        </p>
                        <p><strong><span>（二）委托处理</span></strong></p>
                        <p class="indent-style">
                                <span>我们对您个人信息作出的委托行为，不会超出已征得您授权同意的范围，同时如果您作为个人信息控制者作出的委托行为，也不应超出已征得个人信息主体授权同意的范围，但以下情况除外：</span>
                        </p>
                        <p class="indent-style"><span>1、与个人信息控制者履行法律法规规定的义务相关的；</span></p>
                        <p class="indent-style"><span>2、与国家安全、国防安全直接相关的；</span></p>
                        <p class="indent-style"><span>3、与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
                        <p class="indent-style"><span>4、与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>
                        <p class="indent-style"><span>5、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
                        </p>
                        <p class="indent-style"><span>6、所涉及的个人信息是个人信息主体自行向社会公众公开的；</span></p>
                        <p class="indent-style"><span>7、根据个人信息主体要求签订和履行合同所必需的；</span></p>
                        <p><span>注：隐私政策的主要功能为公开个人信息控制者收集、使用个人信息范围和规则，不应将其视为本条中的合同。</span>
                        </p>
                        <p class="indent-style"><span>8、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
                        </p>
                        <p class="indent-style"><span>9、维护所提供产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span>
                        </p>
                        <p class="indent-style"><span>10、个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；</span></p>
                        <p class="indent-style">
                                <span>11、个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</span>
                        </p>
                        <p class="indent-style">
                                <span>我们会对委托行为进行个人信息安全影响评估，根据有关国家标准的要求，建立适当的数据安全能力，落实必要的管理和技术措施，确保防止您的个人信息的泄漏、损毁、丢失。</span>
                        </p>
                        <p><span>当我们委托第三方处理您的个人信息时，我们会要求受托方遵守下列安全要求：</span></p>
                        <p class="indent-style"><span>1、严格按照我们的要求处理个人信息。受托方因特殊原因未按照我们的要求处理个人信息的，应及时向我们反馈；</span>
                        </p>
                        <p class="indent-style"><span>2、受托方确需再次委托时，应事先征得我们的授权；</span></p>
                        <p class="indent-style"><span>3、协助我们响应您基于个人信息查询、更正、删除、撤回授权同意、注销账户、获取个人信息副本提出的请求；</span>
                        </p>
                        <p class="indent-style"><span>4、受托方在处理个人信息过程中无法提供足够的安全保护水平或发生了安全事件的，应及时向我们反馈；</span>
                        </p>
                        <p class="indent-style"><span>5、在委托关系解除时，受托方不应再存储您的相关个人信息。</span></p>
                        <p><span>我们将对受托方进行监督，方式包括但不限于：</span></p>
                        <p class="indent-style"><span>1、通过合同等方式约定受托方的责任和义务；</span></p>
                        <p class="indent-style"><span>2、对受托方进行审计。</span></p>
                        <p class="indent-style"><span>我们会准确记录和保存委托处理您的个人信息的情况。</span></p>
                        <p class="indent-style">
                                <span>我们得知或者发现受托方未按照委托要求处理个人信息，或未能有效履行个人信息安全保护责任的，会立即要求受托方停止相关行为，且采取或要求受托方采取有效补救措施（如更改口令、回收权限、断开网络连接等）控制或消除个人信息面临的安全风险。必要时我们会终止与受托方的业务关系，并要求其及时删除从我方获得的个人信息。</span>
                        </p>
                        <p><strong><span>（三）转让</span></strong></p>
                        <p class="indent-style">
                                <span>我们不会将您的个人信息转让给任何公司、组织和个人，如您发现我们违反法律法规规定或违反与您的约定向第三方转让您的个人信息，且您要求删除的，我们会立即停止共享、转让的行为，并通知第三方及时删除；但以下情况除外：</span>
                        </p>
                        <p class="indent-style">
                                <span>1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；同时，在转让您的敏感信息前，我们会向您告知所涉及的个人敏感信息类型、数据接收方的身份和数据安全能力，并事先征得您的明示同意；</span>
                        </p>
                        <p class="indent-style"><span>2、根据适用的法律法规、法律程序的要求、或强制性的行政或司法要求所必须的情况进行提供；</span>
                        </p>
                        <p class="indent-style"><span>3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的用户协议）或其他的法律文件约定所提供；</span>
                        </p>
                        <p class="indent-style">
                                <span>4、在涉及合并、收购、资产转让、破产清算或类似情形，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。如该公司或组织破产且无承接方的，我们会对数据做删除处理。</span>
                        </p>
                        <p><strong><span>（四）公开披露</span></strong></p>
                        <p class="indent-style"><span>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，公开披露您的个人信息：</span>
                        </p>
                        <p class="indent-style"><span>1、根据您的需求，获得您明确同意或基于您的主动选择，我们可能会公开披露您所指定的个人信息；</span>
                        </p>
                        <p class="indent-style"><span>2、遵守适用的法律法规、法院判决、裁定或其他法律程序的规定以及相关政府机关或其他法定授权组织的要求；</span>
                        </p>
                        <p class="indent-style">
                                <span>3、为执行用户协议或本隐私政策、维护国家安全或社会公共利益、保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益等合理且必要的用途，不过这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露个人信息。</span>
                        </p>
                        <p class="indent-style">
                                <span>在符合法律法规的前提下，当我们收到上述根据法律、法规的要求、强制性的行政执法或司法要求所必须披露信息的请求时，我们会要求相关部门出具与之相应的法律文件，如传票或调查函，并对相关请求进行慎重的审查，以确保其具备合法依据，且我们披露的信息仅限于执法部门因特定调查目的且有合法权利获取的数据。</span>
                        </p>
                        <p class="indent-style">
                                <span>如您发现我们违反法律法规规定或违反与您的约定，公开披露了您的个人信息，且您要求删除的，我们会立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</span>
                        </p>
                        <p><strong><span>四、我们如何保护您的个人信息</span></strong></p>
                        <p><span>（一）我们努力为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。我们会采取一切合理可行的措施，保护您的个人信息。我们已使用符合业界标准的安全防护措施保护您提供的个人信息，例如，我们会使用加密技术（例如，SSL）、匿名化处理、加密存储等手段来保护你的个人信息；建立受信赖的保护机制防止个人信息遭到恶意攻击；建立访问控制机制，遵守最小权限原则，确保只有授权人员才可访问个人信息；开展安全和个人信息保护培训课程，加强员工对于保护个人信息保护的认识。目前，“灵鸽”软件通过了国家网络安全等级保护（三级）的备案和测评，确保我们对您的个人信息保护达到国家相关要求。</span>
                        </p>
                        <p><span>（二）我们有行业先进的数据安全管理体系，以数据为核心，围绕数据生命周期，从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。我们建立专门的管理制度、流程和组织以保障个人信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。</span>
                        </p>
                        <p><span>（三）我们会采取一切合理可行的措施，确保不收集无关的个人信息。我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span>
                        </p>
                        <p><span>（四）互联网并非绝对安全的环境，我们强烈建议您不要使用此类方式以及非灵鸽推荐的通信方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</span>
                        </p>
                        <p>
                                <span>（五）您可以通过我们的服务建立联系和相互分享。
                                        当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。
                                        请注意，您在使用我们服务时自愿共享或公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发表动态或者在群聊天、圈子等公众场合选择上传包含个人信息的图片等。请您更加谨慎地考虑是否在使用我们的服务时共享或公开分享相关信息。您可通过“灵鸽”软件中的“设置”或我们提供的指引删除您曾经公开分享的信息。但请您注意，这些信息仍可能由其他用户或不受我们控制的非关联第三方独立地保存。</span>
                        </p>
                        <p><span>（六）我们将尽力保障您发送给我们的任何信息的安全性。在不幸发生个人信息安全事件后，请您立即联络灵鸽客服，以便我们根据您的申请采取相应措施。我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
                        </p>
                        <p><strong><span>五、您管理您的个人信息的权利</span></strong></p>
                        <p class="indent-style">
                                <span>我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span>
                        </p>
                        <p><span>（一）查询、更正和补充您的个人信息，并获取您的个人信息副本</span>
                        </p>
                        <p><span>您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</span>
                        </p>
                        <p class="indent-style">
                                <span>1、账户信息——如果您希望访问或编辑您的账户中的个人证件信息，手机绑定、支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过登录账号通过“更多”-“设置”-“账号与安全”执行此类操作。</span>
                        </p>
                        <p class="indent-style">
                                <span>2、个人资料——如果您希望访问或编辑您个人资料中的昵称、头像、家乡、情感状态、出生年月日、星座、职业、学校、兴趣爱好以及地点足迹其他资料等，您可以通过登录账户通过“更多”-“查看或编辑个人资料”执行此类操作。</span>
                        </p>
                        <p class="indent-style"><span>3、动态信息——您可以登录账号通过“我的动态”访问或清除您的动态发表历史记录。</span>
                        </p>
                        <p class="indent-style"><span>如果您无法通过上述路径访问该等个人信息，您可以随时通过灵鸽客服与我们取得联系。我们将在15天内回复您的访问请求。</span>
                        </p>
                        <p class="indent-style"><span>对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（六）响应您的上述请求”中的相关安排向您提供。</span>
                        </p>
                        <p><span>（二）更正您的个人信息</span></p>
                        <p class="indent-style">
                                <span>1、当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正，具体操作方式为：您可以通过灵鸽客服联系我们进行更正。</span>
                        </p>
                        <p class="indent-style"><span>2、如果您无法通过上述方式更正个人信息，您可以随时通过灵鸽客服与我们取得联系，我们将在15天内回复您的更正请求。</span>
                        </p>
                        <p><span>（三）删除您的个人信息</span></p>
                        <p class="indent-style"><span>您可以通过本条“（一）查询、更正或补充您的个人信息，并获得您的个人信息副本”中列明的方式删除您的部分个人信息。</span>
                        </p>
                        <p><span>在以下情形中，您可以向我们提出删除个人信息的请求：</span></p>
                        <p class="indent-style"><span>1、如果我们处理个人信息的行为违反法律法规；</span></p>
                        <p class="indent-style"><span>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</span></p>
                        <p class="indent-style"><span>3、如果我们处理个人信息的行为严重违反了与您的约定；</span></p>
                        <p class="indent-style"><span>4、如果您不再使用我们的产品或服务，或您主动注销了账号；</span></p>
                        <p class="indent-style"><span>5、如果我们永久不再为您提供产品或服务。</span></p>
                        <p class="indent-style">
                                <strong><span>若我们决定响应您的删除请求，我们还将同时通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。当您或我们协助您从我们的服务中删除信息后，依据相关法律法规的规定，我们可能不会立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</span></strong>
                        </p>
                        <p><span>（四）改变您授权同意的范围或撤回您的授权</span></p>
                        <p class="indent-style">
                                <span>请您理解，灵鸽的业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一条”）。除此之外，对于其他个人信息的额外收集和使用，您可以在“更多-设置”或与灵鸽客服联系给予或收回您的授权同意。当您收回授权同意后，我们将无法继续为您提供收回授权同意所对应的服务，也将不再处理相应的个人信息。但您收回授权同意的决定，不会影响此前基于您的授权已开展的个人信息处理。</span>
                        </p>
                        <p><span>（五）个人信息主体注销账户</span>
                        </p>
                        <p class="indent-style">
                                <span>您可以自行在灵鸽APP,进入“我的”—“设置”—“账号与安全”—“注销灵鸽账号”页面提交账户注销申请。在您主动注销账户之后，我们将停止为您提供产品或服务，除法律法规另有规定外，我们根据适用法律的要求删除您的个人信息，或使其匿名化处理。</span>
                        </p>
                        <p><span>（六）约束信息系统自动决策</span>
                        </p>
                        <p class="indent-style">
                                <span>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害灵鸽商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</span>
                        </p>
                        <p><span>（七）响应您的上述请求</span></p>
                        <p class="indent-style">
                                <span>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您的请求，我们将在收到您反馈并验证您身份后的15天内向您做出答复。</span>
                        </p>
                        <p class="indent-style">
                                <strong><span>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</span></strong>
                        </p>
                        <p class="indent-style"><span>当我们决定拒绝响应您的请求时，我们将在收到您反馈后的15天内向您说明拒绝理由。</span></p>
                        <p class="indent-style">
                                <span>如您不满意我们的答复，或对您的数据主体权利存在任何疑问、投诉、意见或建议的，您可以通过本政策第九条提供的联系方式与我们联系。</span>
                        </p>
                        <p class="indent-style"><span>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</span></p>
                        <p class="indent-style"><span>1、与个人信息控制者履行法律法规规定的义务相关的；</span></p>
                        <p class="indent-style"><span>2、与国家安全、国防安全有关的；</span></p>
                        <p class="indent-style"><span>3、与公共安全、公共卫生、重大公共利益有关的；</span></p>
                        <p class="indent-style"><span>4、与刑事侦查、起诉、审判和执行判决等有关的；</span></p>
                        <p class="indent-style"><span>5、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span></p>
                        <p class="indent-style"><span>6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
                        </p>
                        <p class="indent-style"><span>7、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span></p>
                        <p class="indent-style"><span>8、涉及商业秘密的。</span></p>
                        <p><strong><span>六、我们如何处理未成年人的个人信息</span></strong></p>
                        <p><span>（一）任何18岁以下的未成年人未取得家长或监护人同意时均不得创建自己的用户账户及/或使用我们的服务。</span>
                        </p>
                        <p><span>（二）我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您监护人的同意。灵鸽根据国家相关法律法规的规定保护未成年人的个人信息。我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下收集、使用、共享、转让或披露未成年人的个人信息。如果有事实证明我们在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息或未成年人并未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。</span>
                        </p>
                        <p><span>（三）对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：</span></p>
                        <p class="indent-style">
                                <span>1、对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。</span>
                        </p>
                        <p class="indent-style">
                                <span>2、当您作为监护人为被监护的儿童选择使用灵鸽相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用晒单、评价及问答功能分享相关信息时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的服务，您应正确引导并予以监护。</span>
                        </p>
                        <p class="indent-style">
                                <span>3、儿童或其监护人有权随时访问和更正儿童个人信息，还可以向我们提出更正和删除的请求。如您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们。我们会随时为您提供帮助。</span>
                        </p>
                        <p><strong><span>七、我们如何存储您的个人信息</span></strong></p>
                        <p class="indent-style"><span>我们在中华人民共和国境内运营中收集和产生的您的个人信息，将被存储在中华人民共和国境内。</span>
                        </p>
                        <p class="indent-style">
                                <span>一般而言，我们仅在本隐私条款所述目的所必需的最短时间和法律法规要求的时限内保留您的个人信息，但基于以下原因我们可能更改个人信息的存储时间：</span>
                        </p>
                        <p class="indent-style"><span>1、遵守适用的法律法规等有关规定；</span></p>
                        <p class="indent-style"><span>2、遵守法院判决、裁定或其他法律程序的规定；</span></p>
                        <p class="indent-style"><span>3、遵守相关政府机关或其他法定授权组织的要求；</span></p>
                        <p class="indent-style"><span>4、我们有理由确信需要遵守法律法规等有关规定；</span></p>
                        <p class="indent-style">
                                <span>为执行用户协议或本隐私条款、维护国家安全或社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途；在您的个人信息超出存储期限后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。</span>
                        </p>
                        <p class="indent-style">
                                <span>当我们的产品或服务的全部或某项发生停止运营的情形时，我们将以推送通知、公告等形式通知您，并及时停止对您个人信息的收集，同时在合理的期限内删除或匿名化处理您与终止服务或产品相关的个人信息。</span>
                        </p>
                        <p><strong><span>八、本隐私权政策如何更新</span></strong>
                        </p>
                        <p class="indent-style">
                                <span>为给您提供更好的服务以及随着我们业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会限制或削减您按照本隐私权政策所应享有的权利。我们会在“灵鸽”软件和官网内发出更新版本并在生效前通过公告或以其他适当方式提醒您相关内容的更新，也请您及时登录“灵鸽”软件以了解最新的隐私政策。</span>
                        </p>
                        <p class="indent-style">
                                <span>对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于灵鸽公示、向您提供弹窗提示的方式进行通知，说明隐私政策的具体变更内容）。</span>
                        </p>
                        <p><span>本隐私政策所指的重大变更包括但不限于：</span></p>
                        <p class="indent-style"><span>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span>
                        </p>
                        <p class="indent-style"><span>2、我们在所有权结构、控制权、组织架构等方面发生重大变化。如业务调整、破产、并购重组等引起的所有者变更等；</span>
                        </p>
                        <p class="indent-style"><span>3、个人信息共享、转让或公开披露的主要对象发生变化；</span></p>
                        <p class="indent-style"><span>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</span></p>
                        <p class="indent-style"><span>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span></p>
                        <p class="indent-style"><span>6、个人信息安全影响评估报告表明存在高风险时。</span></p>
                        <p class="indent-style"><span>我们还会将本隐私权政策的旧版本存档，供您查阅。</span></p>
                        <p>
                                <strong><span>九、如何联系我们</span></strong>
                        </p>
                        <p class="indent-style"><span>您可以通过以下方式与我们联系，我们将在15天内回复您的请求：</span></p>
                        <p class="indent-style"><span>1、如对本隐私政策内容或您个人信息的相关事宜有任何疑问、意见或建议，您可通过灵鸽客服与我们联系；</span>
                        </p>
                        <p class="indent-style"><span>2、如发现个人信息可能被泄露，您可以发邮件至tousujubao@ringle.com投诉举报；</span>
                        </p>
                        <p class="indent-style">
                                <span>3、如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向相关监管部门进行投诉或举报。</span>
                        </p>
                        <p class="indent-style pb"><span>4、如因本协议产生任何争议，我们将与您友好协商；协商不成，双方均可向深圳市南山区人民法院提起诉讼。</span>
                        </p>
                </section>
    </div>

    <ad-footer style="margin-top: 100px"></ad-footer>
  </div>
</template>

<script>
// 隐私政策
export default {
  name: "adProtocolComponent",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ad-protocol {
    padding-top: 50px !important;
    .content-container {
      max-width: 100% !important;
      padding: 20px !important;
      border-radius: 0px !important;
      color: #222222 !important;
      .title {
        width: 100% !important;
        padding: 10px 0px 20px 0px !important;
        font-size: 18px !important;
      }
      .main {
        width: 100% !important;
        p {
          font-size: 13px !important;
        }
      }
    }
  }
}
.ad-protocol {
  width: 100%;
  padding-top: 130px;
  box-sizing: border-box;
  .content-container {
    max-width: 1180px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    padding-bottom: 100px;
    .title {
      width: 948px;
      margin: 0px auto;
      font-size: 30px;
      font-family: "tj";
      font-weight: 600;
      color: #222222;
      line-height: 42px;
      padding: 100px 0px 59px 0px;
    }
    .main {
      width: 948px;
      margin: 0px auto;
      p {
        font-size: 16px;
        font-family: Noto Sans SC;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
}
.indent-style {
  text-indent: 28px !important;
}

.indent-style span {
  font-family: Noto Sans SC !important;
  font-size: 15px !important;
}

strong > span {
  font-family: Noto Sans SC !important;
  font-size: 15px !important;
}

.pb {
  padding-bottom: 32px;
}

.ml {
  margin-left: 28px;
}
</style>
